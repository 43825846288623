import React from "react";
import Layout from "../components/layout";
import JSONData from "../../content/interests.json";

export default function Interests() {
	return (
		<Layout title="Interests">
			<div className="under-construction">
				{/* <h4 dangerouslySetInnerHTML={{ __html: JSONData.subtitle }} /> */}
			</div>
		</Layout>
	)
};
